import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Stack,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {
  SmartContract,
  ValidContractInstance,
  useAddress,
  useContract,
  useContractRead,
  useContractWrite
} from "@thirdweb-dev/react";
import CurrencySwapIcons from "../../Icons/currency-swap-icons";
import { PropsWithRef, useEffect, useMemo, useRef, useState } from "react";
import PoolCard from "../Pool";
import AbiStakev1 from "../../../abi/AbiStake.json";
import AbiStakev2 from "../../../abi/AbiStakev2.json";
import { BaseContract, BigNumber, ethers } from "ethers";
import { tokenValueTxt } from "../../../utils/formatters";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import Bignumber from "bignumber.js";
import { TPropsPoolCardStake } from "./type";
import EnableBox, { EnableStatus } from "../EnableBox";
import GenericERC20 from "../../../abi/GenericERC20.json";

const calculateAPY = (tokenPrice: string, totalLpDepositedInStaking: string, reward = 38051750, time = 365) => {
  try {
    //BLOCKS_PER_DAY varies acccording to network all values are approx and they keep changing
    //BLOCKS_PER_DAY = 21600 for Kovan Testnet
    //BLOCKS_PER_DAY = 28800 for BSC Testnet
    //BLOCKS_PER_DAY = 6400 for Ethereum Mainnet
    //I am using the value for Ethereum mainnet

    const BLOCKS_PER_YEAR = 28800 * time;

    let rewardTokenPrice = "0";
    // For Price IN ETH
    // Reward Token is Dodgecoin in our case
    rewardTokenPrice = tokenPrice.toString();
    // For Price in BNB
    // If you want to do calculations in BNB uncomment line below and comment line number 13
    // rewardTokenPrice = await getDodgecoinPriceInBNB()

    // REWARD_PER_BLOCK = Number of tokens your farming contract gives out per block
    const REWARD_PER_BLOCK = reward;
    const totalRewardPricePerYear = new Bignumber(rewardTokenPrice).times(REWARD_PER_BLOCK).times(BLOCKS_PER_YEAR);

    // Get Total LP Tokens Deposited in Farming Contract
    // Calculate Total Price Of LP Tokens in Contract
    const totalPriceOfLpTokensInFarmingContract = new Bignumber(rewardTokenPrice).times(totalLpDepositedInStaking);

    // Calculate APY
    const apy = totalRewardPricePerYear.div(totalPriceOfLpTokensInFarmingContract).times(100);

    // Return apy if apy is a valid number or return 0
    return apy.isNaN() || !apy.isFinite() ? 0 : apy.toNumber();
  } catch (e) {
    console.log(e);
    return 0;
  }
};

const EndBlockBscCountDown = async (block: number) => {
  const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.binance.org");
  const blockNumber = await provider.getBlockNumber();
  let result = {
    CurrentBlock: blockNumber,
    CountdownBlock: block,
    RemainingBlock: block - blockNumber,
    EstimateTimeInSec: 1240008.8
  };
  // let res = await axios.get(
  //   `https://api.bscscan.com/api?module=block&action=getblockcountdown&blockno=${block}&apikey=3NJTQBMGHNKR61Y1ZTE7DDE6NZZMWE6SR5`
  // );
  // console.log(res)
  return result;
};

const coinAddr = "0x4BBD4fa12b2B874A13e9555F5C5d0F6aD035ACc3";
// const coinAddr = "0x5FbDB2315678afecb367f032d93F642f64180aa3" // testlocal

const PoolCardStake: React.FC<PropsWithRef<TPropsPoolCardStake>> = (props) => {
  const { item, tokenPrice } = props;
  const {
    tags,
    abi,
    address: stakeAddr,
    sctime,
    reward,
    start: Start,
    end: blocks,
    maxAmount,
    status,
    from,
    to,
    type
  } = item;

  const address = useAddress();
  const [APY, setAPY] = useState(0);
  const [Block, setBlock] = useState({
    CountdownBlock: 0,
    CurrentBlock: 0,
    EstimateTimeInSec: 0,
    RemainingBlock: 0
  });
  const [statusEnable, setStatusEnable] = useState(EnableStatus.NoStake);

  const toast = useToast();

  const { isOpen, onOpen: onOpenAlert, onClose } = useDisclosure();
  const cancelRef = useRef<any>();

  const start = useMemo(() => parseInt(Start), [Start]);

  const AbiStake: any = useMemo(() => (abi == "v1" ? AbiStakev1 : AbiStakev2), [abi]);

  const { contract: stakeContract } = useContract(stakeAddr, AbiStake);
  const { contract: ERC20Contract } = useContract(coinAddr, GenericERC20);

  const { data: pendingReward, refetch: getPendingReward } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "pendingReward",
    (string | undefined)[],
    BigNumber
  >(stakeContract, "pendingReward", [address]);

  const { data: allowance } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "allowance",
    any[],
    BigNumber
  >(ERC20Contract, "allowance", [address, stakeAddr]);

  const { data: timeLeft } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "getTimeLeft",
    any[],
    BigNumber
  >(stakeContract, "getTimeLeft", [address]);

  const { data: Balance, refetch: getBalance } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "balanceOf",
    any[],
    BigNumber
  >(ERC20Contract, "balanceOf", [address]);

  const { data: totalStaked, refetch: getTotalStaked } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "totalStaked",
    unknown[],
    BigNumber
  >(stakeContract, "totalStaked");

  const { data: userInfo, refetch: getUserInfo } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "userInfo",
    any[],
    {
      amount: BigNumber;
      rewardDebt: BigNumber;
      timeEnd: BigNumber;
      timeStart: BigNumber;
    }
  >(stakeContract, "userInfo", [address]);

  const { mutateAsync: approveERC20 } = useContractWrite(ERC20Contract, "approve");

  const { mutateAsync: deposit } = useContractWrite(stakeContract, "deposit");

  const { mutateAsync: withdrawStake } = useContractWrite(stakeContract, "withdraw");

  const { mutateAsync: claimStake } = useContractWrite(stakeContract, "claim");

  const { mutateAsync: emergencyWithdraw } = useContractWrite(stakeContract, "emergencyWithdraw");

  const totalLpDepositedInStakingContract = useMemo(() => totalStaked, [totalStaked]);

  const blocksEnd = useMemo(() => {
    return Block.RemainingBlock > 0 ? (
      Block.CurrentBlock < start ? (
        `Not Started, Remaining: ${start - Block.CurrentBlock} blocks`
      ) : (
        <a target="_blank" href={`https://bscscan.com/block/countdown/${Block.CountdownBlock}`} rel="noreferrer">
          {tokenValueTxt(Block.RemainingBlock, 0, "Blocks")}
        </a>
      )
    ) : (
      "ENDED"
    );
  }, [Block]);

  const updateData = () => {
    getBalance();
    getTotalStaked();
    getUserInfo();
  };

  useEffect(() => {
    EndBlockBscCountDown(parseInt(blocks)).then((r) => {
      if (r.RemainingBlock) {
        setBlock(r);
      }
    });
    getPendingReward();

    const intervalId = setInterval(() => {
      EndBlockBscCountDown(parseInt(blocks)).then((r) => {
        if (r.RemainingBlock) {
          setBlock(r);
        }
      });
      getPendingReward();
    }, 8000);

    return () => clearInterval(intervalId);
  }, [blocks]);

  useEffect(() => {
    // console.log({
    //   tokenPrice,
    //   totalLpDepositedInStakingContract
    // })
    if (tokenPrice && totalLpDepositedInStakingContract) {
      const APYNumber = calculateAPY(tokenPrice.toString(), totalLpDepositedInStakingContract.toString(), reward);
      console.log("APYNumber", APYNumber);
      setAPY(APYNumber);
    }
  }, [tokenPrice, totalLpDepositedInStakingContract]);

  useEffect(() => {
    if (status == "finished") {
      setStatusEnable(EnableStatus.Finished);
    } else {
      if (!allowance || allowance.eq(0)) {
        setStatusEnable(EnableStatus.NoStake);
      } else {
        setStatusEnable(EnableStatus.Aproved);
      }
    }
  });

  const sucessToast = (message: string) => {
    toast({
      title: "Success tx.",
      description: message,
      status: "success",
      duration: 9000,
      isClosable: true
    });
    updateData();
  };

  const errorToast = (message: string) => {
    toast({
      title: "Error tx.",
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true
    });
  };

  const handleApprove = async () => {
    try {
      const res = await approveERC20({ args: [stakeAddr, "999999999000000000000000000"] });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };
  const handleWithDraw = async () => {
    try {
      // const res = await withdrawStake({ args: [userInfo?.amount] });
      const res1 = await claimStake({ args: [] });
      sucessToast(res1.receipt.transactionHash);
      const res2 = await emergencyWithdraw({ args: [] });
      sucessToast(res2.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };
  const handleClaimRewards = async () => {
    try {
      const res = await claimStake({ args: [] });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };

  const handleStakeToken = async (amount: number) => {
    try {
      const res = await deposit({ args: [parseUnits(amount.toString(), 8), sctime] });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };

  const handleUnStakeToken = async (amount: number) => {
    try {
      const res = await withdrawStake({ args: [parseUnits(amount.toString(), 8)] });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };

  const handleClickEmergencyWithdraw = async () => {
    try {
      const res = await emergencyWithdraw({ args: [] });
      sucessToast(res.receipt.transactionHash);
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  };

  const components = [
    <CurrencySwapIcons from={from} to={to} />,
    <Stack direction="column">
      {tags.map(({ css, tag }) => (
        <Badge variant="solid" colorScheme={css}>
          {tag}
        </Badge>
      ))}
    </Stack>,
    <Box color="black" textTransform="uppercase" fontWeight="medium">
      <Text
        fontSize={{ base: "xs", sm: "sm" }}
        fontWeight="medium"
        color="GrayText"
        mb={1}
        display={{ base: "block", sm: "none" }}
      >
        Earned
      </Text>
      {parseFloat(formatUnits(pendingReward?.toString() || "0", 8)).toFixed(3)}
    </Box>,
    <Box color="black" textTransform="uppercase" fontWeight="medium">
      <Text
        fontSize={{ base: "xs", sm: "sm" }}
        fontWeight="medium"
        color="GrayText"
        mb={1}
        display={{ base: "block", sm: "none" }}
      >
        APR
      </Text>
      {APY ? APY.toFixed(3) : 'loading'}%
    </Box>,
    <Box
      display={{ base: "none", lg: "block" }}
      fontSize={{ base: "xs", sm: "sm" }}
      color="black"
      textTransform="uppercase"
    >
      {tokenValueTxt(parseInt(formatUnits(totalStaked?.toString() || 0, 5)), 3, "VSION")}
    </Box>,
    <Box
      display={{ base: "none", lg: "block" }}
      fontSize={{ base: "xs", sm: "sm" }}
      color="black"
      textTransform="uppercase"
    >
      {blocksEnd}
    </Box>
  ];

  const extraCollapse = [
    <EnableBox
      key={2}
      toToken={to}
      fromToken={from}
      allowance={allowance}
      timeLeft={timeLeft}
      pendingReward={pendingReward}
      RemainingBlock={Block.RemainingBlock}
      Balance={Balance}
      Staked={userInfo?.amount}
      isUnstake={(type == 'block' && Block.RemainingBlock <= 0) || type != 'block'}
      contractAddress={stakeAddr}
      AbiContract={AbiStake}
      maxDeposit={maxAmount}
      statusBox={statusEnable}
      onAppove={handleApprove}
      onWithDraw={handleWithDraw}
      onClaimRewards={handleClaimRewards}
      onStakeToken={handleStakeToken}
      onUnstakeToken={handleUnStakeToken}
    />,
    <Stack minHeight="124.5px" key={3}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Text fontSize="1.2rem" color="#aa2b68">
          Total Staked:
        </Text>
        <Text fontSize="1rem" color="purple">
          {tokenValueTxt(parseInt(formatUnits(totalStaked?.toString() || 0, 5)), 3, "VSION")}
        </Text>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between">
        <Text fontSize="1.2rem" color="#aa2b68">
          Ends in:
        </Text>
        <Text fontSize="1rem" color="purple">
          {blocksEnd}
        </Text>
      </Stack>
    </Stack>,
    <>
      <Button isDisabled bgColor={"gray.400"} onClick={() => onOpenAlert()}>
        Emergency Withdraw
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Emergency withdraw</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            You can only withdraw your 'capital' now. Current profits will be lost as you are using the emergency
            withdrawal function. Do you wish to proceed?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme="red" ref={cancelRef} onClick={onClose}>
              Not Withdraw
            </Button>
            <Button colorScheme="green" ml={3} onClick={handleClickEmergencyWithdraw}>
              Withdraw
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  ];

  return <PoolCard typePool="stake" components={components} extraCollapse={extraCollapse} />;
};

export default PoolCardStake;
