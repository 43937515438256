/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from "react";

import type { StackProps } from "@chakra-ui/react";
import { Stack, Text, useColorModeValue, Image } from "@chakra-ui/react";
import { AiOutlineBarChart } from "react-icons/ai";
import { BiBookBookmark, BiGitCompare, BiLayerPlus, BiSolidFlame, BiWallet } from "react-icons/bi";
import { BsFillSuitClubFill, BsCreditCardFill } from "react-icons/bs";
//import { RiNftFill } from "react-icons/ri";
import { GiFarmTractor } from "react-icons/gi";
//import { FaFutbol,GiPressureCooker } from "react-icons/fa";

//import { SiBetfair } from "react-icons/si";
import { IoLogoGameControllerB } from "react-icons/io";

import { MdCardMembership } from "react-icons/md";

import { Link } from "react-router-dom";

import vsnCoin from "../../assets/images/vsn.png";
import { useGetPriceTokenToBNB } from "../../hooks/useGetPriceTokenToBNB";
//import { Audited } from "../Banners/Audit";
import { MainNavLink } from "./MainNavLink";

type NavBarProps = {
  isCompact?: boolean;
  onClick?: () => void;
} & StackProps;

export const NavBar = ({ isCompact, onClick, ...rest }: NavBarProps) => {
  const groupColor = useColorModeValue("gray.400", "gray.600");
  const balanceVSION = useGetPriceTokenToBNB();
  const balanceVSN = useGetPriceTokenToBNB("0x1Aaaef9426db36ebE4D5Ef23DA81f141dbC30725", "VSN");

  const routes = [
    {
      path: "/dashboard",
      label: "Dashboard",
      icon: <BiWallet />,
      withR: true,
      soon: false
    },
    {
      path: "/dashboard/buy",
      label: "Buy Crypto",
      icon: <BsCreditCardFill />,
      withR: true,
      soon: false
    },
    {
      path: "/dashboard/dap",
      label: "DAP",
      icon: <MdCardMembership />,
      withR: true,
      soon: false
    },
    {
      path: "/dashboard/burn",
      label: "Burn",
      icon: <BiSolidFlame />,
      withR: true,
      soon: false
    },

    {
      path: "/dashboard/swap",
      label: "Swap/Bridge",
      icon: <BiGitCompare />,
      withR: true,
      soon: false
    },
    {
      path: "/dashboard/info",
      label: "Info",
      icon: <AiOutlineBarChart />,
      withR: true,
      soon: false
    },
    /* {
      path: "/dashboard/",
      label: "Market Place",
      icon: <RiNftFill />,
      withR: true,
      soon: true
    },*/

    {
      label: "Earning"
    },
    {
      path: "/dashboard/stake",
      label: "Stake",
      icon: <BiLayerPlus />,
      withR: true,
      soon: false
    },
    {
      path: "/dashboard/farm",
      label: "Farming",
      icon: <GiFarmTractor />,
      withR: true,
      soon: false
    },

    {
      label: "Learning"
    },
    {
      path: "/dashboard/academy",
      label: "Academy",
      icon: <BiBookBookmark />,
      withR: true,
      soon: false
    },
    {
      label: "Games"
    },
    {
      path: "/dashboard/games/lottery",
      label: "Lottery",
      icon: <BsFillSuitClubFill />,
      withR: true,
      soon: false
    },
    {
      path: "/dashboard/games/p2e",
      label: "Play Game",
      icon: <IoLogoGameControllerB />,
      withR: true,
      soon: false
    },
    {
      path: "/dashboard/games/space-combat",
      label: "Space combat",
      icon: <IoLogoGameControllerB />,
      withR: true,
      soon: false
    }
    /*{
      path: "/dashboard/games/predictions",
      label: "Predictions",
      icon: <GiPressureCooker />,
      withR: true,
      soon: false
    }
    {
      path: "/dashboard/games/bets",
      label: "Bets",
      icon: <FaFutbol />,
      withR: true,
      soon: true
    },

    {
      path: "/dashboard/games/c2e",
      label: "C2E",
      icon: <RiExchangeDollarLine />,
      withR: true,
      soon: true
    }
  {
      label: "Launchpad"
    },

    {
      path: "/dashboard/xlaunchpad",
      label: "Launchpad List",
      icon: <BsFillRocketTakeoffFill />,
      withR: true,
      soon: true
    },
    {
      path: "/dashboard/create/Presale",
      label: "Create Presale",
      icon: <MdOutlineCrisisAlert />,
      withR: true,
      soon: true
    },
    {
      path: "/dashboard/create/fairlaunch",
      label: "Create Fairlaunch",
      icon: <SiBetfair />,
      withR: true,
      soon: true
    },

    {
      path: "/dashboard/create/token",
      label: "Create Token",
      icon: <FaBitcoin />,
      withR: true,
      soon: true
    },
    {
      label: "Lock"
    },

    {
      path: "/create/lock/",
      label: "Locks",
      icon: <BiSolidLock />,
      withR: true,
      soon: true
    },
    {
      path: "/lock/token/",
      label: "Token lock",
      icon: <BsDatabaseFillLock />,
      withR: true,
      soon: true
    }*/
    //
  ];

  const menu = useMemo(() => {
    return routes.map((r, index) => (
      <Stack key={index}>
        {r.withR ? (
          <>
            <MainNavLink
              leftIcon={r.icon}
              as={Link}
              to={r.soon ? "#" : r.path}
              label={r.label}
              href={r.path}
              size="lg"
              soon={r.soon}
            ></MainNavLink>{" "}
          </>
        ) : (
          <>
            <Text
              px={4}
              color={groupColor}
              fontSize="xs"
              textTransform="uppercase"
              fontWeight="bold"
              letterSpacing="wider"
              display={{ base: isCompact ? "none" : "block", "2xl": "block" }}
            >
              {" "}
              {r.label}
            </Text>
          </>
        )}
      </Stack>
    ));
  }, [routes]);

  return (
    <Stack width="full" mt={10} flex="1 1 0%" spacing={2} {...rest}>
      {menu}

      <Stack width="full" mt={4} spacing={0}>
        <MainNavLink
          isCompact={isCompact}
          size="sm"
          label={`${balanceVSION}`}
          leftIcon={<Image src="https://bscscan.com/token/images/criptovision_32.png?v=2" w="30px" h="30px" />}
          data-test="navigation-settings-button"
        />
        <MainNavLink
          isCompact={isCompact}
          size="sm"
          label={`${balanceVSN}`}
          leftIcon={<Image src={vsnCoin} w="30px" h="30px" />}
          data-test="navigation-settings-button"
        />
      </Stack>
    </Stack>
  );
};
