// import { Link } from "react-router-dom";
// import { FaUserTie } from "react-icons/fa";AiOutlineDesktop AiFillStar
import { AiOutlineClockCircle } from "react-icons/ai";
import { Box, Image, Avatar, Heading, Text, Stack, Button, Link } from "@chakra-ui/react";

import placeHolderCourse from "../../assets/images/academy.jpeg";

const CourseCard = () => {
  return (
    <Box
      borderRadius="20px"
      bg="white"
      border="2px solid #e8e8e8"
      marginBottom="30px"
      p={{ base: "10px 5px", md: "10px" }}
      display="flex"
      alignItems="center"
      transition="all ease .4s"
      flexDirection={{ base: "column", xl: "row" }}
      _hover={{ border: "2px solid #af117d" }}
    >
      <Box borderRadius="20px">
        <Image width="693px" h={"auto"} borderRadius="20px" src={placeHolderCourse} alt="Course Img" />
      </Box>
      <Box flex={1} padding={{ base: "22px 15px", md: "45px 55px" }}>
        <Box mb="20px">
          <Text
            p={{ base: "0 15px", md: "0 13px" }}
            fontSize="14px"
            fontWeight="700"
            letterSpacing=".6px"
            textAlign="center"
            borderRadius="3px"
            textTransform="uppercase"
            transition="all ease .4s"
            backgroundColor="#FFCC33"
            color="#000"
            display="inline-block"
          >
            Trading
          </Text>
        </Box>
        <Heading as="h3" color="#000" fontSize={{ base: "24px", md: "34px" }}>
          Curso básico de Trading Apalancado
        </Heading>
        <Stack gap="17px" flexDirection="row" mt="17px" mb={{ base: "10px", md: "15px" }}>
          {/* <Box
            color="#444444"
            fontSize={{ base: "12px", md: "15px" }}
            display="flex"
            alignItems="center"
            gap={{ base: "3px", md: "10px" }}
          >
            <FaUserTie />
            <Text as="span" fontSize={{ base: "12px", md: "15px" }} color="#444444">
              204 Students
            </Text>
          </Box>{" "}
          <Box
            color="#444444"
            fontSize={{ base: "12px", md: "15px" }}
            display="flex"
            alignItems="center"
            gap={{ base: "3px", md: "10px" }}
          >
            <AiOutlineDesktop />
            <Text as="span" fontSize={{ base: "12px", md: "15px" }} color="#444444">
              12 Leson
            </Text>
          </Box>{" "} */}
          <Box
            color="#444444"
            fontSize={{ base: "12px", md: "15px" }}
            display="flex"
            alignItems="center"
            gap={{ base: "3px", md: "10px" }}
          >
            <AiOutlineClockCircle />
            <Text as="span" fontSize={{ base: "12px", md: "15px" }} color="#444444">
              Del 5 al 9 febrero 2024
            </Text>
          </Box>
        </Stack>

        <Text
          p={{ base: "0 15px", md: "0 13px" }}
          mb={{ base: "10px", md: "15px" }}
          fontSize="12px"
          letterSpacing=".6px"
          color="#000"
        >
          Aprende los fundamentos esenciales del trading, comprende las estrategias de apalancamiento y descubre cómo
          maximizar tus inversiones de manera segura. Este curso te proporcionará las habilidades clave para operar en
          los mercados financieros con confianza y eficacia, guiándote a través de conceptos básicos, herramientas y
          técnicas esenciales para aprovechar al máximo las oportunidades de trading
        </Text>

        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" w="100%">
          <Text fontSize="40px" fontWeight="black" color="#af117d">
            CUPO: $27
          </Text>
          <Button as={Link} href="https://forms.gle/BbvScUtoVMYuFuH8A" target="_blank" className="btn-mtks">
            {" "}
            Inscríbete
          </Button>
        </Stack>

        <Stack
          pt="20px"
          mt={{ base: "20px", md: "45px" }}
          borderTop="1px solid #e8e8e8"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
        >
          <Stack flexDirection="row" alignItems="center" gap="15px">
            <Avatar
              name="Nelson Cabrera Medina"
              src="https://media.licdn.com/dms/image/D4E03AQH49TuQvSDLsg/profile-displayphoto-shrink_800_800/0/1700032859772?e=1709164800&v=beta&t=MOopNKUrEi2Kgo-CHLTBJxIWODYqRbx9gNIxRNDLiyA"
            />
            <Text color="#444444" fontSize="14px">
              Nelson Cabrera Medina
            </Text>
          </Stack>
          {/* <Stack flexDirection="row" alignItems="center" gap="5px">
            <Stack flexDirection="row" alignItems="center" gap="0.2rem">
              {[1, 2, 3, 4, 5].map((star) => (
                <AiFillStar fontSize="16px" key={star} color="#f6b800" />
              ))}
            </Stack>

            <Text color="#444444" fontSize="14px" fontWeight="bold">
              (5.0)
            </Text>
          </Stack> */}
        </Stack>
      </Box>
    </Box>
  );
};

export default CourseCard;
