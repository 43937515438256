import { Box, Flex, Heading, Card, Text } from "@chakra-ui/react";

import { PageContainer } from "../../components/PageContainer";
export default function Xp2p() {
  return (
    <Box
      m="0"
      p="5"
      className="text-center"
      h="100vh"
      background="linear-gradient(97.53deg, #0f071d 5.6%, #0f071d 59.16%, #af117d 200.34%)"
      backgroundSize="cover"
      backgroundPosition="top left"
    >
      <PageContainer pt={{ base: 8, md: "7.5rem" }} pb={{ base: 0, md: "7.5rem" }}>
        <Flex
          flexDir={{ base: "column", xl: "row" }}
          alignItems="center"
          justifyContent="space-between"
          width="full"
          gap={6}
        >
          <Flex
            flexDir="column"
            flex={1}
            gap={4}
            alignItems={{ base: "center", xl: "flex-start" }}
            textAlign={{ base: "center", xl: "left" }}
          >
            <Heading
              fontSize={{ base: "4xl", xl: "6xl" }}
              lineHeight="1em"
              letterSpacing="-0.05em"
              color="whiteAlpha.900"
            >
              Buy Crypto P2P <br />
              <Text
                as="span"
                background="linear-gradient(97.53deg, #F687B3 5.6%, #7B61FF 59.16%, #16D1A1 119.34%)"
                backgroundClip="text"
              >
                Use a credit card, debit card or bank account.
              </Text>
            </Heading>
            <Text fontSize="sm" color="gray.500">
              Buy Bitcoin, Ethereum, USDT, USDC or other top cryptocurrencies. Non-KYC providers available.
            </Text>

            <Text fontSize="sm" color="gray.500">
              *You can also sell crypto directly for cash into your bank account.
            </Text>
          </Flex>
          <Box flexBasis="400px">
            <Card mx={{ base: -4, md: 0 }}>
              <iframe
                src="https://buy.onramper.com?themeName=dark&containerColor=0f071d&primaryColor=46de8c&secondaryColor=3f3f43&cardColor=272727&primaryTextColor=ffffff&secondaryTextColor=ffffff&borderRadius=2&wgBorderRadius=2"
                title="Vsion Ecosystem"
                height="630px"
                width="420px"
                allow="accelerometer; autoplay; camera; gyroscope; payment"
              />{" "}
            </Card>
          </Box>
        </Flex>
      </PageContainer>
    </Box>
  );
}
