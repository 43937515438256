import { Box, Card, CardBody, Stat, StatHelpText, StatNumber, Image } from "@chakra-ui/react";
import { formatUnits, getAddress } from "ethers/lib/utils";
import { useContract, useContractRead } from "@thirdweb-dev/react";
import { BigNumber } from "ethers";
import GenericERC20Abi from "../../../abi/GenericERC20.json";
import DapRewardAbi from "../../../abi/DapReward.abi.json";
import ButtonRewardOwner from "../ButtonRewardOwner";

interface TPropsButtonReward {
  idToken: string;
  name: string;
  img: string;
}

const dapRewardAddr = "0xc8364B02bBE37D4C2E856B14599F4E5eF9c188CC";
const CardBalanceToken: React.FC<TPropsButtonReward> = (props) => {
  const { idToken, name, img } = props;
  const { contract: DapRewardContract } = useContract(dapRewardAddr, DapRewardAbi);
  const { data: rewardToken = { token: getAddress("0xc8364B02bBE37D4C2E856B14599F4E5eF9c188CC") } } = useContractRead(
    DapRewardContract,
    "rewardTokens",
    [idToken]
  );
  const { contract: TokenRewardContract } = useContract(rewardToken.token, GenericERC20Abi);
  const { data: decimalRewardToken } = useContractRead(TokenRewardContract, "decimals");
  const { data: symbolRewardToken } = useContractRead(TokenRewardContract, "symbol");

  const { data: balanceRewardToken = BigNumber.from(0) } = useContractRead(TokenRewardContract, "balanceOf", [
    dapRewardAddr
  ]);

  return (
    <Box borderWidth="1px" borderColor="#af0f7d" borderRadius="lg" overflow="hidden">
      <Card>
        <CardBody>
          <Image w={30} h={30} src={img} fallbackSrc="https://via.placeholder.com/150" />
          <Stat>
            <StatNumber> {name}</StatNumber>
            <ButtonRewardOwner idToken={idToken} />
            <StatHelpText>
              Balance{" "}
              {Number(formatUnits(balanceRewardToken.toString(), decimalRewardToken)?.toString()).toFixed(2) || 0}{" "}
              {symbolRewardToken}
            </StatHelpText>
          </Stat>
        </CardBody>
      </Card>
    </Box>
  );
};

export default CardBalanceToken;
