import React from "react";
import { useBurnData } from "../../hooks/useBurnData";
import {
  Card,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Grid,
  Spacer,
  Box,
  GridItem,
  Image,
  Heading,
  Mark,
  Center
} from "@chakra-ui/react";
import himg from "../../assets/images/wpaper.jpg";
import imgHeader from "../../assets/images/icons-headers/03.png";
import HeaderPage from "../../components/HeaderPage";
const Header = () =>
  HeaderPage(
    "Events",
    " Burn Vsion",

    "",
    `Discover Burn Vision, the groundbreaking DeFi platform that combines blockchain transparency with traditional financial versatility to maximize your digital assets.
`,
    "",
    imgHeader,
    ""
  );
const Burn = () => {
  const { balanceBurn, suPlyVsion, percentajeBurn, percentajeCirc } = useBurnData();

  return (
    <Box p="5">
      <Header />
      <Grid
        templateColumns={{
          base: "repeat(1,minmax(0,1fr))",
          sm: "repeat(2,minmax(0,1fr))",
          md: "repeat(3,minmax(0,1fr))"
        }}
        gap={{ base: 3, md: 6 }}
        p={{ base: 2, md: 10 }}
      >
        <GridItem>
          <Box borderWidth="1px" borderColor="#af0f7d" borderRadius="lg" overflow="hidden">
            <Card>
              <CardBody>
                <Stat>
                  <StatNumber>{suPlyVsion} VSION</StatNumber>
                  <StatHelpText>Total Supply</StatHelpText>
                </Stat>
              </CardBody>
            </Card>
          </Box>
        </GridItem>
        <GridItem>
          {" "}
          <Box borderWidth="1px" borderRadius="lg" overflow="hidden" borderColor="#ffb106">
            <Card>
              <CardBody>
                <Stat>
                  <StatNumber>{balanceBurn} VSION</StatNumber>
                  <StatHelpText> Total Burn VSION ({percentajeBurn}%)</StatHelpText>
                </Stat>
              </CardBody>
            </Card>
          </Box>
        </GridItem>
        <GridItem>
          <Box borderWidth="1px" borderRadius="lg" overflow="hidden" borderColor="#6aab00">
            <Card>
              <CardBody>
                <Stat>
                  <StatNumber>{suPlyVsion - balanceBurn} VSION</StatNumber>
                  <StatHelpText>Total Circulant ({percentajeCirc}%)</StatHelpText>
                </Stat>
              </CardBody>
            </Card>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Burn;
